import { Timestamp } from 'firebase/firestore';
import { User } from '../../backend';
import { ID } from '../../../types/utils';
import {
    UserInformation as v2UserInformation,
    Purchase as v2Purchase,
    startingState as v2StartingState,
    PURCHASE_STATUS,
} from '../v2/types';

/**
 * The user record type for version 1 of the user data migration.
 *
 * Notable modifications:
 * - Live data is now stored as a reference to a snapshot.
 * - Snapshots are now stored as a record of storage references.
 * - `version`: `3` - The version of the user record.
 */

export type UserDocument = {
    liveDataRef: SnapshotRef;
    snapshots: Record<ID, SnapshotRef>;
    user: v2UserInformation;
    version: 3;
};

export type SnapshotRef = {
    licenseUsage: number;
    lastModified: Timestamp;
    label: string;
    id: ID;
    isDemo?: boolean;
};

export type DataStoreData = {
    userDocument: UserDocument | null;
    authInstance: User | null;
    demos: Record<ID, SnapshotRef> | null;
    version: 3;
};

export type { v2UserInformation as UserInformation, v2Purchase as Purchase };
export { PURCHASE_STATUS };
export const startingState: DataStoreData = {
    ...v2StartingState,
    demos: null,
    userDocument: null,
    version: 3,
};
