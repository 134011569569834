import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

export function ColoredLabel({
    labelColor,
    children,
    inline,
    ...props
}: BoxProps & {
    labelColor: number | null;
    inline?: boolean;
    children?: ReactNode;
}) {
    return (
        <Box
            {...props}
            sx={{
                paddingY: 0.75,
                paddingX: 1.5,
                borderRadius: 1.7,
                bgcolor: `hsla(${labelColor}, 60%, 90%, 1)`,
                color: `hsla(${labelColor}, 60%, 30%, 1)`,
                fontWeight: 'bold',
                // Center everything vertically
                display: inline ? 'inline-flex' : 'flex',
                flexBasis: 'auto',
                flexDirection: 'row',
                alignItems: 'flex-start',
                // Make it shrink to fit
                flexShrink: 0,
                ...props.sx,
            }}
        >
            <Box
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
