import { Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CloudProvider } from '../../cloud/backend';
import { Link as RouterLink } from 'react-router-dom';
import BaseAuthScreen from '../BaseAuthenticationScreen';

export default function LoginPage() {
    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [loginFailedMessage, setLoginFailedMessage] = useState<string | null>(null);

    const handleLogin = async (email: string | null, password: string | null) => {
        if (!email || !password) {
            setLoginFailedMessage('Please fill out all fields correctly.');
            return;
        }
        setLoginFailedMessage(null);
        return CloudProvider.Authentication.signIn(email, password)
            .catch(() => setLoginFailedMessage('The username or password is incorrect'))
            .finally(() => {});
    };
    return (
        <BaseAuthScreen
            showLogo
            errorText={loginFailedMessage}
            subtitle="Sign in to your account"
            actionButton={{
                text: 'Sign in',
                onClick: () => handleLogin(email, password),
                requiredFields: ['email', 'username', 'password'],
            }}
            fields={[
                {
                    id: 'email',
                    label: 'Email',
                    type: 'text',
                    value: email ?? '',
                    setValue: setEmail,
                    validate: (value) => value.length > 0,
                },
                {
                    id: 'password',
                    label: 'Password',
                    type: 'password',
                    value: password ?? '',
                    setValue: setPassword,
                    validate: (value) => value.length > 0,
                },
            ]}
            subtexts={[
                <Typography variant="body2" color="whitesmoke" sx={{ textAlign: 'center' }} key="forgotpassword">
                    <Link
                        component={RouterLink}
                        to="/forgotpassword"
                        variant="body2"
                        color="primary"
                        sx={{
                            display: 'inline',
                            textDecoration: 'none',
                        }}
                    >
                        Forgot your password?
                    </Link>
                </Typography>,
                <Typography variant="body2" color="whitesmoke" sx={{ textAlign: 'center' }} key="makeaccount">
                    Don&apos;t have an account?{' '}
                    <Link
                        component={RouterLink}
                        to="/register"
                        variant="body2"
                        color="primary"
                        sx={{
                            display: 'inline',
                            textDecoration: 'none',
                        }}
                    >
                        Sign up!
                    </Link>
                </Typography>,
            ]}
        />
    );
}
