import { IconButton, styled, Toolbar, Typography, AppBar, useScrollTrigger, Stack, Box } from '@mui/material';
import { cloneElement, ReactNode, useContext } from 'react';
import { DrawerOpenContext } from '../../misc/nav';
import _ from 'lodash';
import { getDrawerWidth } from './utils.navbar';
// import AccountMenu from './AccountMenu';
import { Menu as MenuIcon } from '@mui/icons-material';

const TB = styled(AppBar)<{ open: boolean }>(({ theme, open }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    width: 'calc(100% - ' + getDrawerWidth(open, theme) + ')',
    // backgroundColor: 'gray',
    transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    borderRadius: `0 0 ${theme.spacing(1)} 0`,
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(0),
    // boxShadow: 'none',
}));

function ElevationTopBar({ children }: { children: ReactNode }) {
    const trigger = useScrollTrigger({
        disableHysteresis: true, // Disable the delay in the response of a system to forces placed on it.
        threshold: 0, // How far the user has to scroll before the effect triggers
    });
    return cloneElement(children as React.ReactElement, {
        elevation: trigger ? 4 : 0,
        sx: trigger
            ? {}
            : {
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: 0,
              },
    });
}

export default function TopBar({
    title,
    actionButtons = [],
}: {
    title?: string | ReactNode;
    actionButtons?: ReactNode[];
}) {
    const { isOpen, toggleDrawerOpen } = useContext(DrawerOpenContext);
    return (
        <ElevationTopBar>
            <TB open={isOpen} color="default" elevation={0}>
                <Toolbar>
                    <IconButton color="inherit" onClick={() => toggleDrawerOpen()}>
                        <MenuIcon />
                    </IconButton>
                    {_.isString(title) ? (
                        <Typography variant="h6" noWrap component="div" sx={{ flex: 1 }} pl={2}>
                            {title}
                        </Typography>
                    ) : (
                        <div style={{ flex: 1, paddingLeft: 2 }}>{title}</div>
                    )}
                    {actionButtons.length > 0 && (
                        <Stack
                            alignItems="center"
                            direction="row"
                            sx={{
                                gap: (theme) => theme.spacing(2),
                            }}
                        >
                            {/* Filter out any falsy values (for when using [cond1 && <Component1 />, cond2 && <Component2 />] syntax) */}
                            {actionButtons.map((button, idx) => button && <Box key={idx}>{button}</Box>)}
                            {/* <AccountMenu key="accountmenu" /> */}
                        </Stack>
                    )}
                </Toolbar>
            </TB>
        </ElevationTopBar>
    );
}
