import { Timestamp } from 'firebase/firestore';
import { ID } from '../../../types/utils';
import { User } from '../../backend';

/**
 * The user record type for version 1 of the user data migration.
 *
 * Notable modifications:
 * - `version`: `1` - The version of the user record.
 * - `userData`: See {@link v1UserData} for new changes.
 * - `lastLogin`: `Date` - The date of the user's last login.`
 * - `createdAt`: `Date` - The date the user's account was created.
 *      - If the user was created before this field was added, it should be set to the date of the migration.
 */

export type UserDocument = {
    user: UserInformation;
    liveData: Snapshot;
    snapshots: Record<ID, Snapshot>;
};

export type UserInformation = {
    displayName: string;
    email: string;
    uid: ID;
    createdAt: Timestamp;
    lastLogin: Timestamp;
    teacherCount: number;
    purchaseHistory: Record<ID, Purchase>;
    solveHistory: Record<ID, any>;
};

export type Snapshot = {
    /** Stringified representation of the datastore */
    datastore: string;
    /** Timestamp of snapshot */
    timestamp: Timestamp;
    /** Named label of snapshot */
    label: string;
    /** ID of the snapshot */
    id: ID;
};

export type DataStoreData = {
    liveData: Snapshot | null;
    snapshots: Record<ID, Snapshot>;
    user: UserInformation | null;
    authInstance: User | null;
    version: 1;
};
export const StartingState: DataStoreData = {
    liveData: null,
    snapshots: {},
    user: null,
    authInstance: null,
    version: 1,
};

/**
 * A purchase made by a user.
 * @version 1
 */
export type Purchase = {
    /** The unique identifier of the purchase */
    id: ID;
    /** The date the purchase was made */
    date: Timestamp;
    /** The number of teachers purchased */
    quantity: number;
    /** The amount paid for the purchase */
    paid: number;
    /** The status of the purchase */
    status: PURCHASE_STATUS;
    /** The user-friendly name of the item purchased */
    name: string;
};

/**
 * The status of a purchase.
 * @version 1
 */
export enum PURCHASE_STATUS {
    PENDING = 'PENDING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
}
