import _ from 'lodash';
import { DrawerOpenContext } from '../../misc/nav';
import { useContext } from 'react';
// import VersionInfo from '../VersionInfo';
import { NavLink, useMatch } from 'react-router-dom';
import {
    List,
    ListItemIcon,
    useTheme,
    Drawer as MuiDrawer,
    ListItem,
    ListItemButton,
    ListItemText,
    CSSObject,
    Theme,
    styled,
    Stack,
    Divider,
} from '@mui/material';
import { Screen, screens } from '../../state/ScreenStore';
import { DrawerHeader, getDrawerWidth } from './utils.navbar';
import glyphAndTextLogo from '../../assets/Glyph-and-Text-Light.svg';
import glyphLogo from '../../assets/images/logo/lightmode/glyph.png';

const expandedDrawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
    width: expandedDrawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    // [theme.breakpoints.up('sm')]: {
    //      width: `calc(${theme.spacing(8)} + 1px)`,
    // },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: expandedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
function NavButton({ screen, disabled = false }: { screen: Screen; disabled: boolean }) {
    const Icon = screen.icon;
    const theme = useTheme();
    // check if the current path matches the screen path, including nested routes if they exist
    const isActive = !!useMatch(screen.path + (screen.nestedRoutes ? '/*' : ''));

    return (
        <ListItem
            key={screen.path} // Theoretically, this should be unique
            disablePadding
            color="secondary"
            slotProps={{
                root: {
                    color: isActive ? theme.palette.secondary.main : undefined,
                },
            }}
        >
            <ListItemButton
                disabled={disabled}
                component={NavLink}
                to={screen.path}
                color="secondary"
                sx={{
                    borderLeft: isActive ? `${theme.palette.secondary.main} 6px solid` : 'none',
                    paddingLeft: isActive ? '16px' : '22px',
                }}
                selected={isActive}
            >
                <ListItemIcon>
                    <Icon color={isActive ? 'secondary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText
                    primary={screen.label}
                    primaryTypographyProps={{ color: isActive ? 'secondary' : 'inherit' }}
                />
            </ListItemButton>
        </ListItem>
    );
}

export default function NavSidebar() {
    const theme = useTheme();
    const { isOpen } = useContext(DrawerOpenContext);
    // useDataStore(); // re-render when dataStore changes

    return (
        <Drawer variant="permanent" open={isOpen}>
            <DrawerHeader>
                <Stack direction="row" justifyContent="center">
                    {isOpen ? (
                        <img
                            src={glyphAndTextLogo}
                            alt="logo"
                            // Force it to fit the width of the drawer
                            style={{
                                maxWidth: getDrawerWidth(isOpen, theme),
                                padding: theme.spacing(2.8),
                            }}
                        />
                    ) : (
                        <img
                            src={glyphLogo}
                            alt="logo"
                            // Force it to fit the width of the drawer
                            style={{
                                maxWidth: getDrawerWidth(isOpen, theme),
                                padding: theme.spacing(1),
                                paddingLeft: theme.spacing(2),
                            }}
                        />
                    )}
                </Stack>
            </DrawerHeader>

            <Divider />
            <List>
                {_.map(_.values(screens), (screen) => {
                    if (screen.hidden) return null;
                    // FIXME: after getStatus is implemented for all, use it
                    // const screenStatus = screen.getStatus();
                    // const screenStatus = ScreenStatus.INCOMPLETE as ScreenStatus;
                    // const isDisabled = screenStatus === ScreenStatus.MISSING_DEPENDENCIES;
                    // const isDependenciesMet = isDependenciesMet(screen);
                    return <NavButton key={screen.path} screen={screen} disabled={false} />;
                })}
            </List>
            {/* <VersionInfo /> */}
        </Drawer>
    );
}
