import { Box, SxProps } from '@mui/material';

export function ZStack({ sx, innerSx, children }: { sx?: SxProps; innerSx?: SxProps; children: React.ReactNode[] }) {
    return (
        <Box sx={sx} style={{ position: 'relative' }}>
            {children.map((child, index) => (
                <Box key={index} sx={{ position: 'absolute', width: '100%', height: '100%', ...innerSx }}>
                    {child}
                </Box>
            ))}
        </Box>
    );
}
