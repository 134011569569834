import { ReactNode } from 'react';
import TopBar from './TopBar';
import { Screen } from '../../state/ScreenStore';
import { Box, Toolbar } from '@mui/material';
import NotImplemented from '../NotImplemented';
import ProfileAvatar from '../ProfileAvatar';
import { useDataStore } from '../../state/DataStore';
import NavSidebar from './Sidebar';
type PageProps = {
    titleOverride?: string;
    actionButton?: ReactNode;
    children: ReactNode;
    currentScreen: Screen;
    isEmpty?: boolean;
};

export default function Page({ titleOverride, actionButton, children, currentScreen, isEmpty }: PageProps) {
    const user = useDataStore((state) => state.authInstance);
    return (
        <Box display="flex" flexGrow={1}>
            <NavSidebar />
            <TopBar
                title={titleOverride || currentScreen.label}
                actionButtons={[user && <ProfileAvatar user={user} />, actionButton]}
            />

            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: undefined,
                    p: 3,
                    height: '100%',
                    minHeight: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundImage: 'url(/static/media/background.png)',
                    backgroundRepeat: 'repeat',
                }}
            >
                <Toolbar />

                {/* {isEmpty ? currentScreen.emptyState && <EmptyPageState {...currentScreen.emptyState} /> : children} */}
                {isEmpty ? <NotImplemented description="Empty State" /> : children}
                <Box sx={{ flexGrow: 1 }} />
            </Box>
        </Box>
    );
}
