import _ from 'lodash';
import { DataStoreData as v1DataStoreData, UserInformation as v1UserInformation } from './types';
import { defaultV0UserRecord, v0UserRecord } from '../v0/types';
import { Timestamp } from 'firebase/firestore';
const TARGET_VERSION = 1;
export default function migrate(data: any): v1DataStoreData {
    console.log('Migrating from v0 to v1', data);
    if ((data.version ?? 0) >= TARGET_VERSION) {
        console.log('Already at target version, skipping migration');
        return data as v1DataStoreData;
    }
    // We first have to handle an empty object, since the first version of the user data
    // didn't have a version number.
    const v0Data: v0UserRecord = _.defaultsDeep(data, defaultV0UserRecord);

    // Now we know the data is at least version 0, so we can start migrating it to version 1.

    const defaultV1UserData: v1UserInformation = {
        // We don't have a createdAt field in the first version, so we'll set it to the date of the migration (now)
        createdAt: Timestamp.now(),
        displayName: v0Data.userData.displayName,
        email: v0Data.userData.email,
        // We don't have a lastLogin field in the first version, so we'll set it to the epoch,
        // to indicate that the user hasn't logged in (since the migration)
        lastLogin: Timestamp.fromMillis(0),
        purchaseHistory: {},
        solveHistory: {},
        teacherCount: 0,
        uid: v0Data.userData.uid,
    };

    const migratedV1UserData = _.defaultsDeep(data, defaultV1UserData) as v1DataStoreData;

    if (!data.snapshots || typeof !data.snapshots === 'object') {
        migratedV1UserData.snapshots = {};
    }
    return {
        ...migratedV1UserData,
        version: TARGET_VERSION,
    };
}
