import {
    Button,
    ButtonProps,
    IconButton,
    IconButtonProps,
    Stack,
    SvgIcon,
    TableCell,
    TableRow,
    Tooltip,
} from '@mui/material';
import { getSnapshotData } from '../utils';
import { SnapshotRef as Snapshot } from '../cloud';
import { Timestamp } from 'firebase/firestore';
import { SnapshotTableMode } from './SnapshotHistoryTable';
import { Link, LinkProps } from 'react-router-dom';

export type SnapshotRowProps = {
    snapshot: Snapshot;
    isLive?: boolean;
    licenses: number;
    applySnap?: () => void;
    mode: SnapshotTableMode;
    primaryAction?: {
        text: string;
        onClick?: (snapshot: Snapshot) => void;
    } & Omit<ButtonProps, 'onClick'>;
    primaryLink?: {
        text: string;
        to: LinkProps['to'];
        target?: '_blank' | '_self' | '_parent' | '_top';
        variant?: ButtonProps['variant'];
        endIcon?: ButtonProps['endIcon'];
    };
    actions?: ({
        icon: typeof SvgIcon;
        tooltip?: string;
        disabledTooltip?: string;
        onClick?: (snapshot: Snapshot) => void;
    } & Omit<IconButtonProps, 'onClick'>)[];
};

export function SnapshotRow({
    snapshot,
    isLive = false,
    licenses,
    primaryAction,
    primaryLink,
    actions,
}: SnapshotRowProps) {
    const snapshotData = getSnapshotData(snapshot);
    const hasExceededLicence = snapshotData.totalTeachers > licenses;
    return (
        <TableRow
            selected={isLive}
            sx={{
                '&:hover': {
                    backgroundColor: (theme) => theme.palette.action.hover,
                },
            }}
        >
            <TableCell
                sx={{
                    fontWeight: isLive ? 'bold' : 'inherit',
                }}
            >
                {isLive ? 'Current Draft' : snapshot.label}
            </TableCell>
            <TableCell>
                {new Timestamp(snapshot.lastModified.seconds, snapshot.lastModified.nanoseconds)
                    .toDate()
                    .toLocaleString()}
            </TableCell>
            <TableCell
                sx={{
                    fontWeight: hasExceededLicence ? 'bold' : 'inherit',
                    color: hasExceededLicence ? 'error.main' : 'inherit',
                }}
            >
                {snapshotData.totalTeachers} / {licenses}
            </TableCell>
            {/* <TableCell>Unsolved</TableCell> */}
            <TableCell>
                {
                    // If we have an action button, render it
                    primaryAction && (
                        <Button {...primaryAction} onClick={() => primaryAction.onClick?.(snapshot)} fullWidth>
                            {primaryAction.text}
                        </Button>
                    )
                }
                {
                    // If we have a primary link, render it
                    primaryLink && (
                        <Button {...primaryLink} fullWidth component={Link}>
                            {primaryLink.text}
                        </Button>
                    )
                }
            </TableCell>
            <TableCell>
                <Stack direction="row" alignSelf="end" justifyContent="flex-end" spacing={1}>
                    {actions?.map((action, index) => (
                        <Tooltip key={index} title={(action.disabled ? action.disabledTooltip : action.tooltip) ?? ''}>
                            <span>
                                <IconButton size="small" {...action} onClick={() => action.onClick?.(snapshot)}>
                                    <action.icon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ))}
                </Stack>
            </TableCell>
        </TableRow>
    );
}
