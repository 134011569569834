import { DataStoreData, startingState } from '../v3/types';
import { DataStoreData as v2DataStoreData, Snapshot as v2Snapshot } from '../v2/types';
import _ from 'lodash';
const TARGET_VERSION = 3;
export default function migrate(v2Data: v2DataStoreData): DataStoreData {
    console.log('Migrating from v2 to v3', v2Data);
    if ((v2Data.version ?? 0) >= TARGET_VERSION) {
        console.log('Already at target version, skipping migration');
        return v2Data as unknown as DataStoreData;
    }
    const draft: DataStoreData = startingState;
    draft.authInstance = v2Data.authInstance;
    draft.demos = null;
    if (v2Data.userDocument === null) draft.userDocument = null;
    else {
        const liveDataData = getSnapshotData(v2Data.userDocument.liveData);
        draft.userDocument = {
            user: v2Data.userDocument.user,
            liveDataRef: {
                id: v2Data.userDocument.liveData.id,
                label: v2Data.userDocument.liveData.label,
                lastModified: liveDataData.lastModified,
                licenseUsage: liveDataData.licenseUsage,
            },
            snapshots: _.mapValues(v2Data.userDocument.snapshots, (snapshot) => {
                const snapshotData = getSnapshotData(snapshot);
                return {
                    licenseUsage: snapshotData.licenseUsage,
                    lastModified: snapshotData.lastModified,
                    label: snapshot.label,
                    id: snapshot.id,
                };
            }),
            version: 3,
        };
    }
    // The opposite of Partial is Required, which makes all properties required.
    // We can use this to ensure that the data store is fully populated.
    return draft;
}

function getSnapshotData(snapshot: v2Snapshot) {
    const data = snapshot.datastore;
    const parsed = JSON.parse(data ?? '{}');
    const licenseUsage = _.size(parsed.teachers);
    return {
        licenseUsage,
        lastModified: snapshot.timestamp,
    };
}
