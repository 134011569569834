import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { CloudProvider } from '../../cloud/backend';
import { Link as RouterLink } from 'react-router-dom';
import BaseAuthScreen from '../BaseAuthenticationScreen';

export default function ForgotPassword() {
    const [email, setEmail] = useState<string | null>(null);
    const [resetFailedMessage, setResetFailedMessage] = useState<string | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const closeDialog = () => setShowConfirmation(false);
    const handleReset = async (email: string | null) => {
        if (!email) {
            setResetFailedMessage('Please fill out all fields correctly.');
            return;
        }
        setResetFailedMessage(null);
        setShowConfirmation(true);
        return CloudProvider.Authentication.sendPasswordResetEmail(email)
            .catch(() => setResetFailedMessage('An error occurred while trying to reset your password.'))
            .finally(() => {});
    };
    return (
        <>
            <BaseAuthScreen
                showLogo
                errorText={resetFailedMessage}
                subtitle="Reset your password"
                actionButton={{
                    text: 'Send me a reset link',
                    onClick: () => handleReset(email),
                    requiredFields: ['email', 'username', 'password'],
                }}
                fields={[
                    {
                        id: 'email',
                        label: 'Email',
                        type: 'text',
                        value: email ?? '',
                        setValue: setEmail,
                        validate: (value) => value.length > 0,
                    },
                ]}
                subtexts={[
                    <Typography variant="body2" color="whitesmoke" sx={{ textAlign: 'center' }} key="login">
                        Remembered your password?{' '}
                        <Link
                            component={RouterLink}
                            to="/login"
                            variant="body2"
                            color="primary"
                            sx={{
                                display: 'inline',
                                textDecoration: 'none',
                            }}
                        >
                            Sign in.
                        </Link>
                    </Typography>,
                    <Typography variant="body2" color="whitesmoke" sx={{ textAlign: 'center' }} key="makeaccount">
                        Don&apos;t have an account?{' '}
                        <Link
                            component={RouterLink}
                            to="/register"
                            variant="body2"
                            color="primary"
                            sx={{
                                display: 'inline',
                                textDecoration: 'none',
                            }}
                        >
                            Sign up.
                        </Link>
                    </Typography>,
                ]}
            />
            <Dialog open={showConfirmation} onClose={closeDialog} fullWidth>
                <DialogTitle>Password Reset Link</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If the email you entered ({email}) is associated with an account, we&apos;ll send you a password
                        reset link. Please check your email.
                        <br />
                        <br />
                        If you forgot your email, please reach out to us at{' '}
                        <Link href="mailto:contact@schedulearn.com&subject=Forgot%20Email">
                            contact@schedulearn.com
                        </Link>
                        .
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit" variant="contained" onClick={closeDialog} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
