import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ColoredLabel } from './utils/ColoredLabel';
import { Purchase, PURCHASE_STATUS } from '../cloud';
import { ID } from '../types/utils';
import _ from 'lodash';
import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';

function PaymentStatusLabel({ status }: { status: PURCHASE_STATUS }) {
    let data = {
        labelColor: 0,
        label: 'Unknown',
    };
    switch (status) {
        case PURCHASE_STATUS.PAID:
            data = {
                labelColor: 120,
                label: 'Complete',
            };
            break;
        case PURCHASE_STATUS.PENDING:
            data = {
                labelColor: 60,
                label: 'Pending',
            };
            break;
        case PURCHASE_STATUS.CANCELLED:
            data = {
                labelColor: 0,
                label: 'Cancelled',
            };
            break;
        case PURCHASE_STATUS.EXPIRED:
            data = {
                labelColor: 0,
                label: 'Expired',
            };
            break;
        default:
            break;
    }
    return (
        <ColoredLabel labelColor={data.labelColor} inline>
            {data.label}
        </ColoredLabel>
    );
}

export default function PaymentHistoryWidget({ payments }: { payments: Record<ID, Purchase> }) {
    const sortedPayments = useMemo(() => _.sortBy(payments, (payment) => payment.date.seconds).reverse(), [payments]);
    return (
        <Paper
            elevation={4}
            sx={{
                padding: 4,
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Paid</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.map(sortedPayments, (payment) => (
                        <TableRow key={payment.id}>
                            <TableCell>{payment.name}</TableCell>
                            <TableCell>x{payment.quantity}</TableCell>
                            <TableCell>
                                {new Timestamp(payment.date.seconds, payment.date.nanoseconds)
                                    .toDate()
                                    .toLocaleString()}
                            </TableCell>
                            <TableCell>${payment.paid}</TableCell>
                            <TableCell>
                                <PaymentStatusLabel status={payment.status} />
                            </TableCell>
                        </TableRow>
                    ))}
                    {_.isEmpty(payments) && (
                        <TableRow>
                            <TableCell colSpan={5}>No purchase history</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
}
