import { DataStoreData as v2DataStoreData, startingState as v2StartingState } from './types';
import { DataStoreData as v1DataStoreData } from '../v1/types';
import { ID } from '../../../types/utils';
import { Timestamp } from 'firebase/firestore';
const TARGET_VERSION = 2;
export default function migrate(v1Data: v1DataStoreData): v2DataStoreData {
    console.log('Migrating from v1 to v2', v1Data);
    if ((v1Data.version ?? 0) >= TARGET_VERSION) {
        console.log('Already at target version, skipping migration');
        return v1Data as unknown as v2DataStoreData;
    }
    const draft: v2DataStoreData = v2StartingState;
    draft.authInstance = v1Data.authInstance;
    draft.demos = null;
    if (v1Data.user !== null) {
        draft.userDocument = {
            user: {
                ...v1Data.user,
                bypassPaywall: false,
            },
            liveData: v1Data.liveData ?? {
                datastore: '{}',
                timestamp: Timestamp.now(),
                label: 'Current Draft',
                id: 'default' as ID,
            },
            snapshots: v1Data.snapshots,
        };
    }

    // The opposite of Partial is Required, which makes all properties required.
    // We can use this to ensure that the data store is fully populated.
    return draft;
}
