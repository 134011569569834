import { initializeApp } from 'firebase/app';
import {
    browserLocalPersistence,
    connectAuthEmulator,
    createUserWithEmailAndPassword,
    getAuth,
    sendPasswordResetEmail,
    setPersistence,
    signInWithEmailAndPassword,
    updateProfile,
} from 'firebase/auth';
import {
    Timestamp,
    Unsubscribe,
    collection,
    connectFirestoreEmulator,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    updateDoc,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { getStorage, connectStorageEmulator, ref, uploadString, deleteObject, getDownloadURL } from 'firebase/storage';
import { ID, uuid } from '../types/utils';
import { useDataStore } from '../state/DataStore';
import { SnapshotRef, USER_DATA_VERSION } from '.';
import { FIREBASE_CONFIG, USE_EMULATORS } from '../constants';
import _ from 'lodash';
const LIVE_DATA_REF_ID = 'liveDataID' as ID;
/** This is a function that does nothing,
 * but it's useful for breaking a promise chain
 * and returning void instead of the promise's value.
 * In truth, it's not necessary, but it makes the code
 * more readable.
 *
 * @param args - Any values
 * @return void
 **/
const dontPassTheReturnValue = () => {};

export type User = {
    displayName: string;
    email: string;
    uid: ID;
};

// Initialize Firebase
initializeApp(FIREBASE_CONFIG);

class Authentication {
    private static _auth = (() => {
        const auth = getAuth();
        if (USE_EMULATORS.AUTH) connectAuthEmulator(auth, 'http://127.0.0.1:9099');
        return auth;
    })();
    private static shouldEnforceEmailVerification = false; //process.env.NODE_ENV === 'production';

    static async signUp(email: string, schoolName: string, password: string, displayName: string) {
        await setPersistence(Authentication._auth, browserLocalPersistence);
        const userCredential = await createUserWithEmailAndPassword(Authentication._auth, email, password);
        await updateProfile(userCredential.user, { displayName });
        await CloudFunctions.setUpAccount({ email, displayName, schoolName }).catch((error) => {
            console.error('Failed to set up account', error);
            Authentication.signOut();
            throw error;
        });
    }

    static async signIn(email: string, password: string) {
        return setPersistence(Authentication._auth, browserLocalPersistence)
            .then(() =>
                signInWithEmailAndPassword(Authentication._auth, email, password).then(async (userCredential) => {
                    if (!userCredential.user.emailVerified && Authentication.shouldEnforceEmailVerification) {
                        throw new Error('Email not verified');
                    }
                    // Get the user's data from the database
                    await Database.initializeDataStore();
                    console.log('setting auth instance', userCredential);
                    useDataStore.getState().setAuthInstance({
                        displayName: userCredential.user.displayName ?? 'unknown',
                        email: userCredential.user.email ?? 'unknown',
                        uid: userCredential.user.uid as ID,
                    });
                    return userCredential;
                })
            )
            .then(dontPassTheReturnValue);
    }

    static async signOut(): Promise<void> {
        Database.deInitializeDataStore();
        useDataStore.getState().resetData();
        return Authentication._auth.signOut();
    }

    static async sendPasswordResetEmail(email: string) {
        return sendPasswordResetEmail(Authentication._auth, email);
    }

    static getCurrentUser(): User | null {
        if (Authentication._auth.currentUser) {
            return {
                displayName: Authentication._auth.currentUser.displayName ?? 'unknown',
                email: Authentication._auth.currentUser.email ?? 'unknown',
                uid: Authentication._auth.currentUser.uid as ID,
            };
        }
        return null;
    }

    static getAndAssertUserID(): User['uid'] {
        const user = Authentication.getCurrentUser();
        if (user) {
            return user.uid;
        } else {
            throw new Error('User not logged in');
        }
    }

    static registerAuthObserver(observer: (user: User | null) => void) {
        return Authentication._auth.onAuthStateChanged(() => {
            const exposedUser = Authentication.getCurrentUser();
            observer(exposedUser);
        });
    }
}

class Database {
    private static _db = (() => {
        const db = getFirestore();
        if (USE_EMULATORS.FIRESTORE) connectFirestoreEmulator(db, '127.0.0.1', 8080);
        return db;
    })();
    private static databaseListener: Unsubscribe | null = null;

    static async listenForUserDataChanges(user: User) {
        const docRef = doc(Database._db, 'users', user.uid);
        return (Database.databaseListener = onSnapshot(docRef, (doc) => {
            if (!doc.exists()) {
                console.error("User's data not found");
                return;
            }
            useDataStore.getState().setUserDocument(doc.data() as any);
        }));
    }

    static async initializeDataStore(): Promise<void> {
        console.debug('Initializing data store');
        const userID = Authentication.getAndAssertUserID();
        const docRef = doc(Database._db, 'users', userID);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            throw new Error('User data not found');
        }
        const data = docSnap.data() as any;
        useDataStore.getState().setUserDocument(data);
        // Check if we migrated the data, so we can save it back
        if (data.version !== USER_DATA_VERSION) {
            // If we migrated the data on the client, we need
            // to save it to the cloud before we continue
            const migratedUserData = useDataStore.getState().userDocument;
            if (migratedUserData !== null) {
                await updateDoc(docRef, migratedUserData);
                console.info('Migrated user data');
            }
        }

        // Pull the demos
        const demos: Record<ID, SnapshotRef> = {};
        const demosDocRefs = await getDocs(collection(Database._db, 'demos'));
        demosDocRefs.forEach((doc) => {
            demos[doc.id as ID] = doc.data() as SnapshotRef;
        });
        console.log('Setting demos', demos, demosDocRefs);
        useDataStore.getState().setDemos(_.isEmpty(demos) ? null : demos);
    }

    static async deInitializeDataStore(): Promise<void> {
        Database.databaseListener?.();
        useDataStore.getState().resetData();
    }

    static async updateSnapshotRef(snapshot: SnapshotRef) {
        const userID = Authentication.getAndAssertUserID();
        const docRef = doc(Database._db, 'users', userID);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data() as any;
        const dataToUpdate = { ...userData };
        dataToUpdate.snapshots = {
            ...userData.snapshots,
            [snapshot.id]: snapshot,
        };

        // Save the data
        return await updateDoc(docRef, dataToUpdate).then(null);
    }

    static async resetLiveData(snapshot: SnapshotRef) {
        const userID = Authentication.getAndAssertUserID();
        const docRef = doc(Database._db, 'users', userID);

        const newSnap: SnapshotRef = {
            ...snapshot,
            licenseUsage: 0,
            lastModified: Timestamp.now(),
        };
        await updateDoc(docRef, { liveDataRef: newSnap });
        await Storage.resetSnapshot(snapshot);
    }

    static async revertToSnapshot(snapshot: SnapshotRef) {
        let newLiveSnapshot = snapshot;
        // We copy the snapshot to the user's data, overwriting the live data
        console.info('Reverting to snapshot', snapshot.id);
        const userID = Authentication.getAndAssertUserID();
        const userDocRef = doc(Database._db, 'users', userID);
        // If the snapshot is a demo, we need to copy it to the user's data _first_
        if (snapshot.isDemo) {
            console.info('Copying demo snapshot to user data');
            const demoRef = doc(Database._db, 'demos', snapshot.id);
            const demoDoc = await getDoc(demoRef);
            newLiveSnapshot = demoDoc.data() as SnapshotRef;
        }
        newLiveSnapshot = {
            ...newLiveSnapshot,
            isDemo: false,
            id: LIVE_DATA_REF_ID,
        };
        await Storage.duplicateSnapshot(snapshot, newLiveSnapshot);
        await updateDoc(userDocRef, {
            liveDataRef: newLiveSnapshot,
        });
    }

    static async deleteSnapshot(snapshot: SnapshotRef) {
        const userID = Authentication.getAndAssertUserID();
        const docRef = doc(Database._db, 'users', userID);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data() as any;
        const dataToUpdate = { ...userData };
        delete dataToUpdate.snapshots[snapshot.id];
        await updateDoc(docRef, dataToUpdate);
        return Storage.deleteSnapshot(snapshot).then(dontPassTheReturnValue);
    }

    static async updateLastSeen() {
        const userID = Authentication.getAndAssertUserID();
        const docRef = doc(Database._db, 'users', userID);
        return updateDoc(docRef, {
            'user.lastLogin': new Date(),
        }).then(dontPassTheReturnValue);
    }

    static async writeSnapshot(snapshot: SnapshotRef, generateNewID = true) {
        // We need to generate a new ID
        const newID = generateNewID ? uuid() : snapshot.id;
        const newSnapshot: SnapshotRef = {
            ...snapshot,
            id: newID,
            lastModified: Timestamp.now(),
            isDemo: false,
        };

        // Update the user's data
        await Database.updateSnapshotRef(newSnapshot);

        // Copy the snapshot data in storage
        await Storage.duplicateSnapshot(snapshot, newSnapshot);

        return newSnapshot;
    }
}

class CloudFunctions {
    private static _functions = (() => {
        const functions = getFunctions();
        if (USE_EMULATORS.FUNCTIONS) connectFunctionsEmulator(functions, '127.0.0.1', 5001);
        return functions;
    })();
    static async createCheckoutSession({
        quantity,
        uid,
        email,
    }: {
        quantity: number;
        uid: ID;
        email: string;
    }): Promise<any> {
        // Call the function with the user's ID and the quantity of licences they want to purchase
        const createCheckoutSession = httpsCallable(CloudFunctions._functions, 'createCheckoutSession');
        return createCheckoutSession({
            email,
            quantity,
            uid,
            environment: process.env.REACT_APP_ENVIRONMENT,
        }).then((result) => result.data as any);
    }

    static async setUpAccount({
        email,
        displayName,
        schoolName,
    }: {
        email: string;
        displayName: string;
        schoolName: string;
    }): Promise<any> {
        console.log('Setting up account');
        const setUpAccount = httpsCallable(CloudFunctions._functions, 'setUpAccount');
        return setUpAccount({
            email,
            displayName,
            schoolName,
        });
    }
}

class Storage {
    private static _storage = (() => {
        const storage = getStorage();
        if (USE_EMULATORS.STORAGE) connectStorageEmulator(storage, 'localhost', 9199);
        return storage;
    })();

    private static getRefForSnapshotByUser(snapshot: SnapshotRef, userID?: ID) {
        if (snapshot.isDemo) return ref(Storage._storage, `demos/${snapshot.id}.json`);
        if (!userID) {
            userID = Authentication.getAndAssertUserID();
        }
        return ref(Storage._storage, `userdata/${userID}/${snapshot.id}.json`);
    }

    static async deleteSnapshot(snapshot: SnapshotRef) {
        return deleteObject(Storage.getRefForSnapshotByUser(snapshot));
    }

    static async resetSnapshot(snapshot: SnapshotRef) {
        const emptyData = '{}';
        await uploadString(Storage.getRefForSnapshotByUser(snapshot), emptyData, 'raw', {
            contentType: 'application/json',
        });
    }

    static async duplicateSnapshot(oldSnap: SnapshotRef, newSnap: SnapshotRef) {
        const oldRef = Storage.getRefForSnapshotByUser(oldSnap);
        const newRef = Storage.getRefForSnapshotByUser(newSnap);
        return getDownloadURL(oldRef)
            .then((url) => fetch(url))
            .then((res) => res.text())
            .then((data) => uploadString(newRef, data, 'raw', { contentType: 'application/json' }));
    }
}

export const CloudProvider = {
    Authentication,
    Database,
    CloudFunctions,
    Storage,
};
