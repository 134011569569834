import { Box, Button, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import logo from '../assets/Glyph-and-Text-Dark.svg';
import { useState } from 'react';
type BaseAuthScreenProps = {
    showLogo: boolean;
    subtitle: string;
    fields: {
        id: string;
        label: string;
        type: 'text' | 'password';
        value: string;
        setValue: (value: string) => void;
        validate?: (value: string) => boolean;
        validateMessage?: string;
    }[];
    actionButton?: {
        text: string;
        requiredFields?: string[];
        onClick: () => Promise<void>;
    };
    subtexts?: React.ReactNode[];
    errorText?: string | null;
};
export default function BaseAuthScreen(props: BaseAuthScreenProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [modifiedFields, setModifiedFields] = useState<string[]>([]);
    const errorFields = props.fields
        .filter((field) => modifiedFields.includes(field.id) && field.validate && !field.validate(field.value))
        .map((field) => field.id);
    return (
        <Box sx={{ display: 'block', width: '100%', height: '100%' }}>
            <Stack direction="row" width="100%" height="100%">
                <Box flex={1} sx={{ background: 'linear-gradient(0deg, #21FFCF, #2589E4)' }} />
                <Box flex={2} sx={{ background: 'linear-gradient(-30deg, #4600B0, #21FFCF)' }} />
            </Stack>
            {/* This content should appear on top of the background */}
            <Paper
                sx={{
                    background: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '12px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(5px)',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: 4,
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setIsLoading(true);
                        props.actionButton?.onClick().then(() => setIsLoading(false));
                    }}
                >
                    <Stack direction="column" spacing={3} alignItems="center" width="35vw">
                        <Stack direction="column" spacing={2} alignItems="center" width="100%">
                            {props.showLogo && <img src={logo} alt="logo" width="80%" />}
                            <Typography variant="h6" color="whitesmoke">
                                {props.subtitle}
                            </Typography>
                        </Stack>
                        {props.fields.map((field) => (
                            <TextField
                                key={field.id}
                                label={field.label}
                                type={field.type}
                                variant="outlined"
                                sx={{ width: '100%' }}
                                value={field.value}
                                error={errorFields.includes(field.id)}
                                helperText={
                                    errorFields.includes(field.id)
                                        ? field.validateMessage ?? 'This field is required'
                                        : ''
                                }
                                onChange={(e) => {
                                    setModifiedFields([...modifiedFields, field.id]);
                                    field.setValue(e.target.value);
                                }}
                            />
                        ))}

                        {props.actionButton && (
                            <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                sx={{ width: '100%', color: 'whitesmoke' }}
                                disabled={
                                    // If any of the required fields don't pass the validation, disable the button
                                    props.actionButton.requiredFields &&
                                    !props.actionButton.requiredFields.every((fieldID) => {
                                        // If there is no field with the required name, that's ok?
                                        const fieldObject = props.fields.find((f) => f.id === fieldID);
                                        if (!fieldObject) return true;
                                        // If there is no validation function, then it's ok
                                        if (!fieldObject.validate) return true;
                                        return fieldObject.validate(fieldObject.value);
                                    })
                                }
                            >
                                {isLoading ? <CircularProgress size={25} color="inherit" /> : props.actionButton.text}
                            </Button>
                        )}
                        <Typography
                            variant="body1"
                            color="secondary"
                            sx={{
                                textAlign: 'center',
                            }}
                            fontWeight="bold"
                        >
                            {props.errorText}
                        </Typography>
                        <Stack direction="column" spacing={1}>
                            {props.subtexts}
                        </Stack>
                    </Stack>
                </form>
            </Paper>
        </Box>
    );
}
