import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { screenKey } from '../misc/nav';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import HomePage from '../components/Pages/Home';
import { CameraAlt, Payment } from '@mui/icons-material';
import _ from 'lodash';
import BillingPage from '../components/Pages/Billing';
import LoginPage from '../components/Pages/Login';
import Register from '../components/Pages/Register';
import ForgotPassword from '../components/Pages/ForgotPassword';

export interface RouteDefinition {
    path: string;
    _key: screenKey;
    component?: FC;
    header: string;
    nestedRoutes?: RouteDefinition[];
    requiresAuth?: boolean;
}

export interface Screen extends RouteDefinition {
    label: string;
    icon: typeof SvgIcon;
    hidden?: boolean;
}

export const screens: { [key in screenKey]: Screen } = {
    // * NOTE: ┌────────────────────────────────────────────┐
    // * NOTE: │ The path (except for the home path) must  │
    // * NOTE: │ start with a slash. This has something to │
    // * NOTE: │ do with the way getCurrentScreen() works. │
    // * NOTE: └────────────────────────────────────────────┘

    home: {
        path: '/',
        _key: 'home',
        component: HomePage,
        label: 'Snapshots',
        icon: CameraAlt,
        header: 'Snapshots',
        requiresAuth: true,
    },
    billing: {
        path: '/billing',
        _key: 'billing',
        component: BillingPage,
        label: 'Billing',
        icon: Payment,
        header: 'Billing',
        requiresAuth: true,
    },
    login: {
        path: '/login',
        _key: 'login',
        component: LoginPage,
        label: 'Login',
        icon: SvgIcon,
        header: 'Login',
        hidden: true,
    },
    register: {
        path: '/register',
        _key: 'register',
        component: Register,
        label: 'Register',
        icon: SvgIcon,
        header: 'Register',
        hidden: true,
    },
    forgotPassword: {
        path: '/forgotpassword',
        _key: 'forgotPassword',
        component: () => <ForgotPassword />,
        label: 'Forgot Password',
        icon: SvgIcon,
        header: 'Forgot Password',
        hidden: true,
    },
    // resetPassword: {
    //     path: '/reset-password',
    //     component: () => <NotImplemented description="Reset Password" />,
    //     label: 'Reset Password',
    //     icon: SvgIcon,
    //     header: 'Reset Password',
    //     hidden: true,
    // },
} as const;

type ScreenStore = {
    screens: { [key in screenKey]: Screen };
    getCurrentScreen: () => Screen;
};

export const useScreenStore = create<ScreenStore>()(
    immer((set, get) => ({
        screens: screens,
        getCurrentScreen: () => {
            const screenPath = window.location.pathname.split('/')[1];
            return _.find(get().screens, (screen) => screen.path === '/' + screenPath)!;
        },
    }))
);
