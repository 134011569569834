import _ from 'lodash';
import { SnapshotRef } from './cloud';
import { CloudProvider } from './cloud/backend';
import { DASHBOARD_URL } from './constants';

export async function initiateCheckout({ quantity }: { quantity: number }) {
    const currentUser = CloudProvider.Authentication.getCurrentUser();
    console.info('Initiating checkout for user', currentUser);
    if (!currentUser) {
        console.error('User not logged in');
        // Redirect to login page
        window.location.href = DASHBOARD_URL + 'login';
        return;
    }
    // Get the redirect URL
    const checkoutRedirect = (
        await CloudProvider.CloudFunctions.createCheckoutSession({
            quantity,
            email: currentUser.email,
            uid: currentUser.uid,
        })
    ).url;
    // Redirect to the checkout page
    window.location.href = checkoutRedirect;
}
export function getMaxTeacherCount(snaps: SnapshotRef[]): number {
    let max = 0;
    _(snaps)
        .map(getSnapshotData)
        .forEach((data) => (max = Math.max(max, data.totalTeachers)));
    return max;
}

export function wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getTotalSolveSeconds(calendars: SnapshotRef[]) {
    const finishedRuntime = 0;
    const numUnfinished = 0;
    _(calendars);
    // for (const calendar of calendars) {
    //     const solveHistory = calendar.metadata.solutionHistory;
    //     const finishedSolves = solveHistory.filter((solve) => solve.endTime !== undefined);
    //     numUnfinished += solveHistory.length - finishedSolves.length;
    //     for (const solve of finishedSolves) {
    //         const delta = solve.endTime!.toDate().getTime() - solve.startTime.toDate().getTime();
    //         finishedRuntime += delta;
    //     }
    // }
    return {
        finishedRuntime,
        numUnfinished,
    };
}

export function getTimeSensitiveGreeting(time: Date) {
    const hour = time.getHours();
    if (hour >= 6 && hour < 12) {
        return 'Good morning';
    } else if (hour >= 12 && hour < 18) {
        return 'Good afternoon';
    } else {
        return 'Good evening';
    }
}

export function getSnapshotData(snapshot: SnapshotRef): {
    totalTeachers: number;
} {
    return {
        totalTeachers: snapshot.licenseUsage,
    };
}
