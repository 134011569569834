export const FIREBASE_CONFIG = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG ?? '{}');

export const USE_EMULATORS = {
    FIRESTORE: process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_PROD_FIREBASE_FIRESTORE !== 'true',
    AUTH: process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_PROD_FIREBASE_AUTH !== 'true',
    FUNCTIONS: process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_PROD_FIREBASE_FUNCTIONS !== 'true',
    STORAGE: process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_PROD_GCP_STORAGE !== 'true',
};

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const EDITOR_URL = process.env.REACT_APP_EDITOR_URL || '';

export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL || '';
