import { Timestamp } from 'firebase/firestore';
import { User } from '../../backend';
import {
    Purchase as v1Purchase,
    PURCHASE_STATUS as v1PURCHASE_STATUS,
    Snapshot as v1Snapshot,
    UserDocument as v1UserDocument,
    UserInformation as v1UserInformation,
} from '../v1/types';
import { ID } from '../../../types/utils';

/**
 * The user record type for version 1 of the user data migration.
 *
 * Notable modifications:
 * - `bypassPaywall`: `boolean` - Whether the user is allowed to bypass the paywall.
 */

export type UserDocument = v1UserDocument & {
    user: UserInformation;
};

export type UserInformation = v1UserInformation & {
    bypassPaywall: boolean;
};

export type Snapshot = v1Snapshot;

/**
 * Notable modifications:
 * The datastore is subdivided into multiple parts, to allow for more flexibility in the data store.
 * - `userDocument`: The entirety of the previous data store. (encompasses `user`, `liveData`, and `snapshots`)
 * - `authInstance`: The current user's authentication instance.
 * - `demos`: The user's demo data.
 * - `version`: `2` - The version of the user record.
 */
export type DataStoreData = {
    userDocument: UserDocument | null;
    authInstance: User | null;
    demos: Record<ID, Snapshot> | null;
    version: 2;
};

export const startingState: DataStoreData = {
    userDocument: null,
    authInstance: null,
    demos: null,
    version: 2,
};

export const DefaultUserInformation: UserInformation = {
    createdAt: Timestamp.now(),
    displayName: '',
    email: '',
    lastLogin: Timestamp.fromMillis(0),
    purchaseHistory: {},
    solveHistory: {},
    teacherCount: 0,
    uid: '' as ID,
    bypassPaywall: false,
};

export type Purchase = v1Purchase;

export { v1PURCHASE_STATUS as PURCHASE_STATUS };
