import { styled, Theme } from '@mui/material';
import { Toolbar as MuiToolbar } from '@mui/material';

export const expandedDrawerWidth = 240;
export const getTopBarHeight = (theme: Theme) => theme.spacing(7);
export function getDrawerWidth(isOpen: boolean, theme: Theme): string {
    return isOpen ? `${expandedDrawerWidth}px` : `calc(${theme.spacing(7)} + 1px)`;
}
// export const Toolbar = styled(Box)(({ theme }) => ({
//     height: '145px',
// }));
export const Toolbar = MuiToolbar;
export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: getTopBarHeight(theme),
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
