import { Button, CircularProgress, Collapse, Paper, Stack, TextField, Typography } from '@mui/material';
import { ZStack } from './utils/ZStack';
import { initiateCheckout } from '../utils';
import { useDataStore } from '../state/DataStore';
import { useState } from 'react';

function CircularProgressWithLabel(
    props: { value: number; size: number } & ({ textLabel: string } | { children: React.ReactNode })
) {
    const isError = props.value > 100;
    const color = isError ? 'error' : 'primary';
    const cappedVal = isNaN(props.value) ? 0 : Math.min(100, props.value);
    return (
        <ZStack
            sx={{ width: props.size, height: props.size }}
            innerSx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <CircularProgress color={color} variant="determinate" value={100} size={props.size} sx={{ opacity: 0.5 }} />

            <CircularProgress color={color} variant="determinate" value={cappedVal} size={props.size} />
            {'textLabel' in props ? (
                <Typography variant="h6" component="div" color="text.secondary">
                    {props.textLabel}
                </Typography>
            ) : (
                props.children
            )}
        </ZStack>
    );
}

export default function TeacherUsageWidget({ used, total }: { used: number; total: number }) {
    const userID = useDataStore((state) => state.authInstance?.uid);
    const [isShowingCheckout, setShowingCheckout] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [numTeachersWanted, setNumTeachersWanted] = useState(Math.max(1, used - total));
    return (
        <Paper
            elevation={4}
            sx={{
                padding: 4,
            }}
        >
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
                <CircularProgressWithLabel value={(used / total) * 100} size={300}>
                    <Stack direction="column" alignItems="center" justifyContent="center">
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                            <Typography variant="h6" component="div" color="text.secondary">
                                {used}
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" fontSize={50}>
                                /
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary">
                                {total}
                            </Typography>
                        </Stack>
                        <Typography variant="h6" component="div" color="text.secondary">
                            Teachers
                        </Typography>
                    </Stack>
                </CircularProgressWithLabel>
                <Collapse in={!isShowingCheckout} sx={{ width: '100%' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setShowingCheckout(true);
                        }}
                    >
                        Purchase Teacher Licences
                    </Button>
                </Collapse>
                <Collapse in={isShowingCheckout} sx={{ width: '100%' }}>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: '100%' }}
                    >
                        <TextField
                            size="small"
                            label="Number of Licences"
                            type="number"
                            value={numTeachersWanted}
                            onChange={(e) => {
                                setNumTeachersWanted(parseInt(e.target.value));
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (!userID) return;
                                if (isLoading) return;
                                setLoading(true);
                                setTimeout(() => setLoading(false), 5000); // We really just want to show the spinner long enough until the stripe checkout launches
                                initiateCheckout({ quantity: numTeachersWanted }).catch((e) => {
                                    console.error(e);
                                    setLoading(false);
                                });
                            }}
                        >
                            {isLoading ? <CircularProgress sx={{ color: 'white' }} size={24} /> : 'Checkout'}
                        </Button>
                    </Stack>
                </Collapse>
            </Stack>
        </Paper>
    );
}
