import migrateToV1 from './v1/migrate';
import migrateToV2 from './v2/migrate';
import migrateToV3 from './v3/migrate';
import { DataStoreData } from '..';

export function migrate(data: any): DataStoreData {
    console.info('Migrating this user data to latest version', data);
    const result = [migrateToV1, migrateToV2, migrateToV3].reduce((acc, fn) => fn(acc), data);
    console.info('Migration complete', result);
    return result;
}

export function migrateUserDocument(userDocument: any, version: number): DataStoreData['userDocument'] {
    if (!userDocument) return null;
    return migrateToV3(migrateToV2({ ...userDocument, version } as any)).userDocument;
}
