import { Stack } from '@mui/material';
import { useScreenStore } from '../../state/ScreenStore';
import Page from '../navigation/Page';

import { useDataStore } from '../../state/DataStore';
import TeacherUsageWidget from '../TeacherUsageWidget';
import TotalSolveTimeWidget from '../TotalSolveTimeWidget';
import PaymentHistoryWidget from '../PaymentHistoryWidget';
import { useMemo } from 'react';
import { getMaxTeacherCount, getTotalSolveSeconds } from '../../utils';
import { SnapshotRef } from '../../cloud';

export default function BillingPage() {
    const currentScreen = useScreenStore((state) => state.getCurrentScreen());
    const userData = useDataStore((state) => state.userDocument?.user);
    const snapshots: SnapshotRef[] = useMemo(() => [], []); //useDataStore((state) => _.values(state.snapshots));
    const numTeachersUsed = useMemo(() => getMaxTeacherCount(snapshots), [snapshots]);
    const totalSolveTime = useMemo(() => getTotalSolveSeconds(snapshots), [snapshots]);
    if (!userData) {
        return null;
    }
    return (
        <Page currentScreen={currentScreen}>
            <Stack direction="column" spacing={6}>
                <Stack direction="row" spacing={2}>
                    <TeacherUsageWidget used={numTeachersUsed} total={userData.teacherCount} />
                    <TotalSolveTimeWidget seconds={totalSolveTime.finishedRuntime} />
                </Stack>
                <PaymentHistoryWidget payments={userData!.purchaseHistory} />
            </Stack>
        </Page>
    );
}
