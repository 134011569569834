// import NavSidebar from './navigation/NavSidebar';
// import NavMain from './components/NavMain';
import { Alert, Box, CssBaseline, ThemeProvider, Typography } from '@mui/material';

import theme from '../misc/theme';

import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
    createBrowserRouter,
    Outlet,
    RouteObject,
    RouterProvider,
    useLocation,
    useMatch,
    useNavigate,
} from 'react-router-dom';
import { CloudProvider, User } from '../cloud/backend';
import { DrawerOpenContext, RegistrationLogicContext } from '../misc/nav';
import { useDataStore } from '../state/DataStore';
import { RouteDefinition, screens, useScreenStore } from '../state/ScreenStore';
import PageUnimplemented from './PageUnimplemented';
import { USE_EMULATORS } from '../constants';

const router = createBrowserRouter([
    {
        path: '/',
        Component: AppRouter,
        children: _.map(screens, function screenToRoute(screen: RouteDefinition): RouteObject {
            const ScreenComponent = screen.component ?? (() => <PageUnimplemented />);
            return {
                path: screen.path,
                Component: ScreenComponent,
                children: screen.nestedRoutes ? _.map(screen.nestedRoutes, screenToRoute) : undefined,
            };
        }).concat({
            path: '*',
            element: (
                <Typography variant="h1" style={{ margin: 'auto' }}>
                    404 Not Found
                </Typography>
            ),
            children: [],
        }),
    },
]);

function AppRouter() {
    const screen = useScreenStore((state) => state.getCurrentScreen());
    const [authInstance, setAuthInstance] = useDataStore((state) => [state.authInstance, state.setAuthInstance]);
    const [registrationInProgress, setRegistrationInProgress] = useState(false);
    const [isOpen, setOpen] = useState(!!authInstance);
    const toggleDrawerOpen = () => {
        setOpen(!isOpen);
    };
    const matchLoginRoute = useMatch('/login');
    const matchRegisterRoute = useMatch('/register');
    // region Navigation Logic
    const navigate = useNavigate();
    const location = useLocation();
    const navigationLogic = useCallback(
        (user: User | null) => {
            if (registrationInProgress) return;
            if (!user) {
                // If we have logged out
                if (!screen?.requiresAuth) return;
                const redirectState = location.state as { wasAlreadyRedirectedToLogin: boolean } | undefined;
                const wasRedirectedToLogin = redirectState?.wasAlreadyRedirectedToLogin;
                // If we were redirected to the login page, don't redirect us again
                if (wasRedirectedToLogin) {
                    return;
                }
                let attemptedDestination = window.location.pathname;

                // If we're directly navigating to the login page, set the redirection to the home page
                if (attemptedDestination === '/login') {
                    attemptedDestination = '/';
                }

                // If we're directly navigating to the register page, set the redirection to the home page
                if (attemptedDestination === '/register') {
                    attemptedDestination = '/';
                }

                // Otherwise, we got redirected to the login page, so set the redirection to the attempted destination
                // but to avoid infinite loops, we will set a flag to indicate that we were already redirected to the login page
                navigate('/login', { state: { redirect: attemptedDestination, wasAlreadyRedirectedToLogin: true } });
            } else {
                // If we have a redirect query param, navigate to that
                const redirectState = location.state as { redirect: string } | undefined;
                const redirect = redirectState?.redirect;
                setOpen(true);
                if (redirect) {
                    navigate(redirect, { replace: true });
                    return;
                }
                // If the user manually navigated to the login or register page, send them to the home page
                if (matchLoginRoute || matchRegisterRoute) {
                    navigate('/', { replace: true });
                    return;
                }
            }
        },
        [location.state, matchLoginRoute, matchRegisterRoute, navigate, registrationInProgress]
    );
    // endregion

    // Set up a listener for the auth state
    useEffect(() => {
        const unsub1 = CloudProvider.Authentication.registerAuthObserver(setAuthInstance);
        const unsub2 = CloudProvider.Authentication.registerAuthObserver(navigationLogic);

        return () => {
            unsub1();
            unsub2();
        };
    }, [setAuthInstance, navigationLogic]);
    // Set up a **separate** listener for the user data
    useEffect(
        () =>
            CloudProvider.Authentication.registerAuthObserver((usr) => {
                if (usr) {
                    CloudProvider.Database.listenForUserDataChanges(usr);
                }
            }),
        []
    );

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <CssBaseline />
            <DrawerOpenContext.Provider value={{ isOpen: isOpen, toggleDrawerOpen }}>
                <RegistrationLogicContext.Provider
                    value={{
                        registrationInProgress,
                        setRegistrationInProgress,
                    }}
                >
                    <Outlet />
                </RegistrationLogicContext.Provider>
            </DrawerOpenContext.Provider>
            {process.env.NODE_ENV === 'development' &&
                _.values(USE_EMULATORS).includes(false) && ( // If any of the emulators are enabled
                    // We're in development mode, but we're using production resources.
                    // This is VERY DANGEROUS, so the developer must be warned.
                    <Alert
                        severity="error"
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            zIndex: 9999,
                        }}
                    >
                        <b>ATTENTION:</b> DEVELOPMENT BUILD IS USING PRODUCTION RESOURCES [
                        {_.keys(USE_EMULATORS)
                            .filter((key) => !USE_EMULATORS[key as keyof typeof USE_EMULATORS])
                            .join(', ')}
                        ]
                    </Alert>
                )}
        </Box>
    );
}

function App() {
    return (
        // <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
        // </LocalizationProvider>
    );
}

export default App;
