import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ID } from '../types/utils';
import { useDataStore } from '../state/DataStore';
import { SnapshotRef } from '../cloud';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import { SnapshotRow } from './SnapshotRow';
import { DriveFileRenameOutline, DeleteOutline, OpenInNew, CameraAltOutlined, RestartAlt } from '@mui/icons-material';
import { CloudProvider } from '../cloud/backend';
import { EDITOR_URL } from '../constants';
import { SnapshotDialogType } from './Pages/Home';
export enum SnapshotTableMode {
    HISTORY,
    DEMOS,
}
export default function SnapTable({
    snapshots,
    liveDataRef = null,
    mode,
    setOpenDialog,
}: {
    snapshots: Record<ID, SnapshotRef> | null;
    liveDataRef?: SnapshotRef | null;
    mode: SnapshotTableMode;
    setOpenDialog: (dialog: { type: SnapshotDialogType; snapshot: SnapshotRef }) => void;
}) {
    const nameHeader = {
        [SnapshotTableMode.HISTORY]: 'Snapshot Name',
        [SnapshotTableMode.DEMOS]: 'Demo Name',
    }[mode];
    const teacherLicenses = useDataStore((state) => state.userDocument?.user.teacherCount) ?? 0;
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width="25%">{nameHeader}</TableCell>
                    <TableCell width="25%">Last Modified</TableCell>
                    <TableCell width="15%">Teachers</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    <TableCell colSpan={2} width="35%">
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {_.isEmpty(snapshots) && !liveDataRef ? (
                    <TableRow>
                        <TableCell
                            colSpan={4}
                            sx={{
                                textAlign: 'center',
                                fontStyle: 'italic',
                                color: 'gray',
                            }}
                        >
                            {
                                {
                                    [SnapshotTableMode.HISTORY]:
                                        'No snapshots available. You can create them from the editor.',
                                    [SnapshotTableMode.DEMOS]: 'No demos available at the moment.',
                                }[mode]
                            }
                        </TableCell>
                    </TableRow>
                ) : (
                    <>
                        {liveDataRef && (
                            <SnapshotRow
                                licenses={teacherLicenses}
                                snapshot={liveDataRef}
                                mode={mode}
                                isLive
                                {...buildRowActions(mode, liveDataRef, setOpenDialog, true)}
                            />
                        )}

                        {_.values(snapshots)
                            .sort(
                                (a, b) =>
                                    new Timestamp(b.lastModified.seconds, b.lastModified.nanoseconds).toMillis() -
                                    new Timestamp(a.lastModified.seconds, a.lastModified.nanoseconds).toMillis()
                            )
                            // Add the live snapshot to the top of the list
                            .map((snapshot, index) => {
                                return (
                                    <SnapshotRow
                                        licenses={teacherLicenses}
                                        key={index}
                                        snapshot={snapshot}
                                        mode={mode}
                                        {...buildRowActions(mode, snapshot, setOpenDialog)}
                                    />
                                );
                            })}
                    </>
                )}
            </TableBody>
        </Table>
    );
}

function buildRowActions(
    mode: SnapshotTableMode,
    snapshot: SnapshotRef,
    setOpenDialog: (dialog: { type: SnapshotDialogType; snapshot: SnapshotRef }) => void,
    isLiveSnapshot?: boolean
) {
    let actions: Parameters<typeof SnapshotRow>[0]['actions'] = [];
    let primaryAction: Parameters<typeof SnapshotRow>[0]['primaryAction'] = undefined;
    let primaryLink: Parameters<typeof SnapshotRow>[0]['primaryLink'] = undefined;

    switch (mode) {
        case SnapshotTableMode.DEMOS:
            actions = [
                {
                    icon: DeleteOutline,
                    sx: { visibility: 'hidden' },
                },
                {
                    icon: DeleteOutline,
                    color: 'error',
                    disabledTooltip: 'You cannot delete demo snapshots',
                    disabled: true,
                },
            ];
            primaryAction = {
                text: 'View demo snapshot',
                color: 'secondary',
                variant: 'outlined',
                onClick: CloudProvider.Database.revertToSnapshot,
            };
            break;
        case SnapshotTableMode.HISTORY:
            if (!isLiveSnapshot) {
                actions = [
                    {
                        icon: DriveFileRenameOutline,
                        tooltip: 'Rename snapshot',
                        onClick: (snapshot) => setOpenDialog({ type: 'renameSnapshot', snapshot }),
                        color: 'primary',
                    },
                    {
                        icon: DeleteOutline,
                        tooltip: 'Delete snapshot',
                        onClick: (snapshot) => setOpenDialog({ type: 'deleteSnapshot', snapshot }),
                        color: 'error',
                    },
                ];
                primaryAction = {
                    text: 'Switch to snapshot',
                    color: 'secondary',
                    variant: 'outlined',
                    onClick: (snapshot) => {
                        setOpenDialog({ type: 'makeSnapshotToPreventOverwrite', snapshot });
                    },
                };
            } else {
                actions = [
                    {
                        icon: CameraAltOutlined,
                        color: 'secondary',
                        onClick: (snapshot) => setOpenDialog({ type: 'makeSnapshot', snapshot }),
                        tooltip: 'Create a snapshot of the current draft',
                    },
                    {
                        icon: RestartAlt,
                        color: 'error',
                        onClick: () => setOpenDialog({ type: 'reset', snapshot }),
                        tooltip: 'Erase and start over',
                    },
                ];

                primaryLink = {
                    text: 'Open in Editor',
                    to: EDITOR_URL + 'pull',
                    target: '_blank',
                    variant: 'contained',
                    endIcon: <OpenInNew />,
                };
            }

            break;
    }

    return { actions, primaryAction, primaryLink };
}
