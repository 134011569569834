import { createContext } from 'react';
import _ from 'lodash';
export const screenKeys = ['home', 'billing', 'login', 'register', 'forgotPassword'] as const;
export type screenKey = (typeof screenKeys)[number];

export type DrawerOpener = {
    isOpen: boolean;
    toggleDrawerOpen: () => void;
};
export const DrawerOpenContext = createContext<DrawerOpener>({
    isOpen: false,
    toggleDrawerOpen: _.noop,
});
export type RegistrationLogic = {
    registrationInProgress: boolean;
    setRegistrationInProgress: (isLoggingIn: boolean) => void;
};
export const RegistrationLogicContext = createContext<RegistrationLogic>({
    registrationInProgress: false,
    setRegistrationInProgress: _.noop,
});
