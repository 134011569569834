import { createTheme, ThemeOptions } from '@mui/material';

export default createTheme({
	palette: {
		// mode: 'dark',
		mode: 'light',
		primary: {
			main: '#00607A',
		},
		secondary: {
			main: '#7A0060',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					// userSelect: 'auto',
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: 'rgba(122, 0, 96, 0.08)',
					},
					'&.Mui-selected:hover': {
						backgroundColor: 'rgba(122, 0, 96, 0.12)',
					},
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				autoComplete: 'false',
				inputProps: {
					autoComplete: 'false',
				},
			},
		},
	},
	typography: {
		body1: {
			fontSize: '1.1rem',
		},
	},
}) as ThemeOptions;
