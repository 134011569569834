import { Calendar } from '../../../types/Calendar';
import { ID } from '../../../types/utils';

export type v0UserRecord = {
    calendars: Record<ID, Calendar>;
    productionClient: boolean;
    userData: {
        displayName: string;
        email: string;
        uid: ID;
    };
};

export const defaultV0UserRecord: v0UserRecord = {
    calendars: {},
    productionClient: false,
    userData: {
        displayName: '',
        email: '',
        uid: '' as ID,
    },
};
