import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';
import { migrate } from '../cloud/userDataMigration/migrate';
import _ from 'lodash';
import { DataStoreData, startingState } from '../cloud';

type DataStoreSetters = {
    /**
     * Set the initial data for the data store.
     *
     * @note This function will *not* overwrite the user object, but will overwrite the rest of the data.
     * @note This function will migrate the data to the latest version.
     */
    setInitialData: (data: any) => void;
    setDemos: (demos: DataStoreData['demos']) => void;
    setUserDocument: (userDocument: DataStoreData['userDocument']) => void;
    setAuthInstance: (user: DataStoreData['authInstance']) => void;
    resetData: () => void;
    getData: () => DataStoreData;
};

export type DataStore = DataStoreData & DataStoreSetters;

export const useDataStore = create<DataStore>()(
    persist(
        immer((set, get) => ({
            ...startingState,
            resetData: () => set(() => ({ ...startingState })),

            setInitialData(data) {
                set(migrate(data));
            },
            setAuthInstance(user) {
                set((state) => {
                    state.authInstance = user;
                });
            },
            setUserDocument(userDocument) {
                set((state) => {
                    state.userDocument = userDocument; //migrateUserDocument(userDocument, userDocument?.version ?? 0);
                });
            },
            setDemos(demos) {
                set((state) => {
                    state.demos = demos;
                });
            },
            getData: get,
        })),
        {
            name: 'dataStore',
            merge: (_persistedState, initialState) => {
                console.log('Merging persisted state with initial state');
                const persistedState = _persistedState as any;
                return _.defaultsDeep(migrate(persistedState), initialState);
            },
        }
    )
);

// Expose get for testing
// @ts-expect-error -- This is for testing
window.getDataStore = useDataStore.getState().getData;
