import { Divider, Paper, Stack, Typography } from '@mui/material';

function secondsToHMS(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export default function TotalSolveTimeWidget({ seconds }: { seconds: number }) {
    return (
        <Paper
            elevation={4}
            sx={{
                padding: 4,
                flexGrow: 1,
                flexDirection: 'row',
                minWidth: 600,
            }}
        >
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{ width: '100%', height: '70%' }}
            >
                <Stack direction="row" spacing={2} alignItems="baseline" justifyContent="center">
                    <Typography variant="h6" component="div" color="text.secondary" fontSize={64}>
                        {secondsToHMS(seconds)}
                    </Typography>
                    <Typography variant="h6" component="div" color="text.secondary" fontSize={35}>
                        /
                    </Typography>
                    <Typography variant="h6" component="div" color="text.secondary" fontSize={35}>
                        ∞
                    </Typography>
                </Stack>
                <Divider flexItem />
                <Typography variant="h6" component="div" color="text.secondary">
                    Total Solve Time
                </Typography>
            </Stack>
        </Paper>
    );
}
