import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useScreenStore } from '../../state/ScreenStore';
import Page from '../navigation/Page';
import { getTimeSensitiveGreeting, wait } from '../../utils';
import { useDataStore } from '../../state/DataStore';
import SnapshotHistoryTable, { SnapshotTableMode } from '../SnapshotHistoryTable';
import { SnapshotRef } from '../../cloud';
import { useState } from 'react';
import { CloudProvider } from '../../cloud/backend';

export type SnapshotDialogType =
    | 'makeSnapshot'
    | 'reset'
    | 'renameSnapshot'
    | 'deleteSnapshot'
    | 'makeSnapshotToPreventOverwrite';

export default function HomePage() {
    const currentScreen = useScreenStore((state) => state.getCurrentScreen());
    const snapshots = useDataStore((state) => state.userDocument?.snapshots ?? {});
    const liveDataRef = useDataStore((state) => state.userDocument?.liveDataRef);
    const demos = useDataStore((state) => state.demos);
    const user = useDataStore((state) => state.authInstance); // User data might still be undefined on account creation
    const [openDialog, setOpenDialog] = useState<null | {
        type: SnapshotDialogType;
        snapshot: SnapshotRef;
    }>(null);

    return (
        <Page currentScreen={currentScreen}>
            <Stack spacing={2}>
                <Typography variant="h4">
                    {getTimeSensitiveGreeting(new Date())}, {user?.displayName}
                </Typography>
                <Paper>
                    <SnapshotHistoryTable
                        liveDataRef={liveDataRef}
                        snapshots={snapshots}
                        mode={SnapshotTableMode.HISTORY}
                        setOpenDialog={setOpenDialog}
                    />
                </Paper>
                <Paper>
                    <SnapshotHistoryTable
                        snapshots={demos}
                        mode={SnapshotTableMode.DEMOS}
                        setOpenDialog={setOpenDialog}
                    />
                </Paper>
            </Stack>
            {openDialog && (
                <SnapshotDialog
                    type={openDialog.type}
                    snapshot={openDialog.snapshot}
                    onClose={() => setOpenDialog(null)}
                />
            )}
        </Page>
    );
}

type SnapshotDialogContents = Record<
    SnapshotDialogType,
    {
        title: string;
        text: string;
        form: boolean;
        dataLoss: boolean;
        actionText: string;
        action: (snapshot: SnapshotRef) => any;
    }
>;
function SnapshotDialog({
    type,
    snapshot,
    onClose,
}: {
    type: SnapshotDialogType;
    snapshot: SnapshotRef;
    onClose: () => void;
}) {
    const [name, setName] = useState(snapshot.label);
    const [loading, setLoading] = useState(false);
    const dialogContents = dialogConfigurations[type];
    if (!snapshot) return null;

    return (
        <Dialog open onClose={onClose} fullWidth>
            <form
                autoFocus
                onSubmit={async (e) => {
                    e.preventDefault();
                    setLoading(true);
                    // Wait for at least 1/2 second for UX
                    await Promise.all([dialogContents.action({ ...snapshot, label: name }), wait(500)]);
                    setLoading(false);
                    onClose();
                }}
            >
                <DialogTitle>{dialogContents.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogContents.text}
                        {dialogContents.dataLoss && (
                            <Typography variant="body2" color="error">
                                This will overwrite your current draft. If you do not save a snapshot, this draft will
                                be permanently lost.
                            </Typography>
                        )}
                    </DialogContentText>
                    {dialogContents.form && (
                        <TextField
                            autoFocus
                            margin="dense"
                            variant="standard"
                            fullWidth
                            placeholder={snapshot.label}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 120 }}
                        type="submit"
                        disabled={dialogContents.form && name === ''}
                        color={dialogContents.dataLoss ? 'error' : 'primary'}
                    >
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                            <span>{dialogContents.actionText}</span>
                            {loading && <CircularProgress color="inherit" size={16} />}
                        </Stack>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

const dialogConfigurations: SnapshotDialogContents = {
    makeSnapshot: {
        title: 'Make a Snapshot of the Current Draft',
        text: 'Snapshots are a way to save the current state of your draft. You can revert to them at any time.',
        form: true,
        dataLoss: false,
        actionText: 'Save snapshot',
        action: CloudProvider.Database.writeSnapshot,
    },
    reset: {
        title: 'Erase and Start Over',
        text: 'Are you sure you want to erase your draft and start over? You will lose all data in your current draft.',
        form: false,
        dataLoss: true,
        actionText: 'Erase draft',
        action: CloudProvider.Database.resetLiveData,
    },
    renameSnapshot: {
        title: 'Rename Snapshot',
        text: 'Enter the new name for this snapshot',
        form: true,
        dataLoss: false,
        actionText: 'Rename snapshot',
        action: CloudProvider.Database.updateSnapshotRef,
    },
    deleteSnapshot: {
        title: 'Delete Snapshot',
        text: 'Are you sure you want to delete this snapshot? This action cannot be undone.',
        form: false,
        dataLoss: true,
        actionText: 'Delete snapshot',
        action: CloudProvider.Database.deleteSnapshot,
    },
    makeSnapshotToPreventOverwrite: {
        title: 'Load Snapshot to Draft',
        text: 'Loading this snapshot will overwrite your current draft. Are you sure you want to continue?',
        form: false,
        dataLoss: true,
        actionText: 'Load snapshot',
        // Note, when merging with the editor, we want to add a toast here.
        action: CloudProvider.Database.revertToSnapshot,
    },
};
