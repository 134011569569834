import { useScreenStore } from '../state/ScreenStore';
import NotImplemented from './NotImplemented';
import Page from './navigation/Page';

export default function PageUnimplemented() {
    const currentScreen = useScreenStore((state) => state.getCurrentScreen());
    return (
        <Page currentScreen={currentScreen}>
            <NotImplemented description={currentScreen.label} />
        </Page>
    );
}
