import { Link, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { CloudProvider } from '../../cloud/backend';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { RegistrationLogicContext } from '../../misc/nav';
import BaseAuthScreen from '../BaseAuthenticationScreen';
export default function Register() {
    const [email, setEmail] = useState<string | null>(null);
    const [username, setUsername] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [schoolName, setSchoolName] = useState<string | null>(null);
    const [loginFailedMessage, setLoginFailedMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const { setRegistrationInProgress } = useContext(RegistrationLogicContext);

    const handleRegistration = async (
        email: string | null,
        schoolName: string | null,
        username: string | null,
        password: string | null
    ) => {
        if (!email || !username || !password || password.length < 6 || !schoolName) {
            setLoginFailedMessage('Please fill out all fields correctly.');
            return;
        }
        setLoginFailedMessage(null);
        setRegistrationInProgress(true);
        return CloudProvider.Authentication.signUp(email, schoolName, password, username)
            .then(() => {
                navigate('/');
            })
            .catch(() => {
                setLoginFailedMessage('There was a problem creating your account.');
            })
            .finally(() => {
                setRegistrationInProgress(false);
            });
    };
    return (
        <BaseAuthScreen
            showLogo
            errorText={loginFailedMessage}
            subtitle="Make an account with us"
            actionButton={{
                text: 'Sign up',
                onClick: () => handleRegistration(email, schoolName, username, password),
                requiredFields: ['email', 'username', 'password'],
            }}
            fields={[
                {
                    id: 'schoolName',
                    label: 'School name',
                    type: 'text',
                    value: schoolName ?? '',
                    setValue: setSchoolName,
                    validate: (value) => value.length > 0,
                },
                {
                    id: 'username',
                    label: 'Your name',
                    type: 'text',
                    value: username ?? '',
                    setValue: setUsername,
                    validate: (value) => value.length > 0,
                },
                {
                    id: 'email',
                    label: 'Email',
                    type: 'text',
                    value: email ?? '',
                    setValue: setEmail,
                    validate: (value) => value.length > 0,
                },
                {
                    id: 'password',
                    label: 'Password',
                    type: 'password',
                    value: password ?? '',
                    setValue: setPassword,
                    validate: (value) => value.length >= 6,
                    validateMessage: 'Password must be at least 6 characters',
                },
            ]}
            subtexts={[
                <Typography variant="body2" color="whitesmoke" sx={{ textAlign: 'center' }} key="signin">
                    Already have an account?{' '}
                    <Link
                        component={RouterLink}
                        variant="body2"
                        to="/login"
                        color="primary"
                        sx={{
                            display: 'inline',
                            textDecoration: 'none',
                        }}
                    >
                        Sign in{' '}
                    </Link>
                    instead
                </Typography>,
            ]}
        />
    );
}
